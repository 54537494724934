@media (min-width: 640px) {
	.selected-formulary-row .bp3-form-group {
		width: 100%;
	}

	.selected-formulary-frequency {
		display: flex;
		align-items: center;
	}
}

@media (max-width: 639px) {
	.selected-formulary-frequency {
		display: flex;
		align-items: center;
	}
}

.selected-formulary-row {
	display: flex;
	justify-content: space-between;
}

.selected-formulary-input {
	flex: 1;
}

.selected-formulary-spacer {
	margin: 0 10px;
}
