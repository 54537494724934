body {
	--cerulean: #2757ae;
	--sky-blue: #86b8f6;
	--midnight-blue: #001f67;
	--salmon: #ffa59f;
	--light-salmon: #ffbe9f;
	--nice-blue: #1456b4;
	--peach: #ff7165;
	--light-grey: #f5f9fe;
	--mint: #ecfeff;
	--dark-mint: #c7ecee;
	--vibrant-mint: #80e7df;
	--glacier: #f5feff;
}
