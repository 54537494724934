@media (max-width: 639px) {
	:focus {
		outline: none;
	}
}

.bp3-drawer {
	overflow-y: scroll;
}

.bp3-overlay-backdrop {
	background-color: rgba(16, 22, 26, 0.3);
}

.bp3-drawer-header {
	box-shadow: none;
	padding: 0;
	align-items: flex-start;
}
.bp3-drawer-header .bp3-heading {
	color: inherit;
	font-size: 31px;
	line-height: 48px;
	margin-bottom: 55px;
	white-space: inherit;
}

.bp3-popover {
	border-radius: 4px;
}

.bp3-tooltip .bp3-popover-content {
	background-color: var(--glacier);
	color: inherit;
	padding: 17px 23px;
	border-radius: 4px;
	border: 1px solid var(--dark-mint);
	line-height: 28px;
}

.tooltip-small .bp3-popover-content {
	width: 400px;
}

@media (min-width: 640px) {
	.tooltip-large .bp3-popover-content {
		width: 500px;
	}
}
@media (max-width: 639px) {
	.tooltip-large .bp3-popover-content {
		width: 90vw;
	}
}

.bp3-tooltip .bp3-popover-arrow path {
	fill: var(--glacier);
}
