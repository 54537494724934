body {
	margin: 0;
	font-size: 20px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--midnight-blue);
	overflow-x: hidden;
	font-weight: 500;
	height: 100%;
}
html,
#root {
	height: 100%;
}
/* Override browser style sheets */
select,
input,
input::placeholder {
	color: inherit;
	font: inherit;
}

body,
textarea {
	font-family: "objektiv-mk3", "Helvetica Neue", sans-serif;
}

iframe {
	border: none;
}

input::placeholder {
	color: #888888;
}

h2 {
	font-size: 42px;
	font-weight: 700;
	margin: 50px auto;
}

h4 {
	font-size: 25px;
	font-weight: unset;
	margin: 25px auto;
	line-height: 1.68;
	letter-spacing: 0.29px;
}

.h5 {
	@apply text-lg font-medium;
}

.font-unset {
	font-family: unset !important;
}

input:focused + .MuiOutlinedInput-notchedOutline,
input:hover + .MuiOutlinedInput-notchedOutline {
	@apply border-blue-400;
}

.text-underline-accent {
	@apply border-b border-green-300;
}

.arrow-up {
	width: 0;
	height: 0;
	border-left: 1rem solid transparent;
	border-right: 1rem solid transparent;
	border-bottom: 1rem solid currentColor;
}
